// extracted by mini-css-extract-plugin
export var button = "RegistrationForm-module--button--19c13";
export var ccsDiv = "RegistrationForm-module--ccsDiv--a818a";
export var contactDetailsIcon = "RegistrationForm-module--contactDetailsIcon--400ef";
export var contactFormDiv = "RegistrationForm-module--contactFormDiv--7dceb";
export var contactFormInputsDiv = "RegistrationForm-module--contactFormInputsDiv--6d8e1";
export var displayDiv = "RegistrationForm-module--displayDiv--6da52";
export var footerContentDiv = "RegistrationForm-module--footerContentDiv--524f4";
export var footerDiv = "RegistrationForm-module--footerDiv--71d0d";
export var footerLDiv = "RegistrationForm-module--footerLDiv--d3c86";
export var footerLinksDiv = "RegistrationForm-module--footerLinksDiv--5b978";
export var footerLogoDiv = "RegistrationForm-module--footerLogoDiv--764dd";
export var footerRDiv = "RegistrationForm-module--footerRDiv--26f89";
export var formTitleDiv = "RegistrationForm-module--formTitleDiv--7521f";
export var fullNameDiv = "RegistrationForm-module--fullNameDiv--29930";
export var globalCareSupply = "RegistrationForm-module--globalCareSupply--5112d";
export var input = "RegistrationForm-module--input--a264d";
export var inputFieldWIthLabelDiv = "RegistrationForm-module--inputFieldWIthLabelDiv--33c82";
export var inputFieldWIthLabelDiv9 = "RegistrationForm-module--inputFieldWIthLabelDiv9--6f2c5";
export var inputLabelDiv = "RegistrationForm-module--inputLabelDiv--35a67";
export var inputLabelDiv9 = "RegistrationForm-module--inputLabelDiv9--232aa";
export var inputRowDiv = "RegistrationForm-module--inputRowDiv--112a5";
export var inputRowDiv4 = "RegistrationForm-module--inputRowDiv4--1892f";
export var inputSelect = "RegistrationForm-module--inputSelect--cc639";
export var logoA = "RegistrationForm-module--logoA--946cf";
export var passwordsShouldContainAMin = "RegistrationForm-module--passwordsShouldContainAMin--41a62";
export var privacyPolicy = "RegistrationForm-module--privacyPolicy--83c01";
export var registerContentDiv = "RegistrationForm-module--registerContentDiv--ede80";
export var registerSectionDiv = "RegistrationForm-module--registerSectionDiv--d11a4";
export var registrationPageDiv = "RegistrationForm-module--registrationPageDiv--f1f95";
export var udshSymbol1Icon = "RegistrationForm-module--udshSymbol1Icon--6ab79";
export var visaIcon = "RegistrationForm-module--visaIcon--01e92";